/* BUTTONS */
.btn {
  border: 1px solid $dark-gray;
  border-radius: 0;
  padding: 10px 25px;

  &__icon-right {
    padding-left: 15px;
  }
}

.btn-circle {
  border-radius: 50px;
  padding: .3rem .65rem;
  ;
}

.btn-plus,
.btn-minus {
  border: 1px solid $gray-border-select;
  background-color: $white;
  color: $orange;
  font-size: 1.6em;
  font-weight: bold;
  line-height: 2em;
  text-align: center;
  padding: 0;
}

.btn-full {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
}

.btn-orange {
  background-color: $orange !important;
  color: $white !important;
}

.btn-submit {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  width: 10em;
  height: auto;
}

.btn-submit .circle {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 2.5em;
  height: 2.5em;
  background: $orange;
  border-radius: 1.625em;
}

.btn-submit .circle .icon {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

.btn-submit .circle .icon.arrow {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.4em;
  width: 1.125em;
  height: 0.125em;
  background: none;
}

.btn-submit .circle .icon.arrow::before {
  position: absolute;
  content: '';
  top: -0.25em;
  right: 0.0625em;
  width: 0.625em;
  height: 0.625em;
  border-top: 0.125em solid #fff;
  border-right: 0.125em solid #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.btn-submit .button-text {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75em 0;
  margin: 0 0 0 1.85em;
  color: $orange;
  font-weight: 300;
  line-height: 1;
  text-align: center;
}

.btn-submit:hover .circle {
  width: 100%;
}

.btn-submit:hover .circle .icon.arrow {
  background: $white;
  -webkit-transform: translate(1em, 0);
  transform: translate(1em, 0);
}

.btn-submit:hover .button-text {
  color: $white;
}

.btn-add-item {
  line-height: 4em;
  display: inline-block;
  color: $gray;
  border-top: 1px solid $gray-border-select;
  border-bottom: 1px solid $gray-border-select;
  cursor: pointer !important;

  &:hover {
    color: $orange;

    .anticon {
      color: $orange !important;
    }
  }
}