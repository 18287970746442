/* Steps proccess */
.stepsproccess {
  display: table;
  width: 100%;
  position: relative;
  padding-bottom: 2em;

  .stepsproccess-row {
    display: table-row;

    .stepsproccess-step {
      display: table-cell;
      text-align: center;
      position: relative;

      &.pointer {
        cursor: pointer !important;

        .btn-circle {
          cursor: pointer !important;
        }

        p {
          cursor: pointer;
        }

        div {
          cursor: pointer;
        }
      }

      .btn {
        position: relative;
        z-index: 999;
        cursor: default !important;
      }

      .dashed-line-prev,
      .dashed-line-next {
        position: absolute;
        top: 3.8em;
        content: " ";
        width: 50%;
        height: 1px;
        border-top: 2px dashed $light-gray;
        z-index: 0;
      }

      .dashed-line-next {
        right: 0.15em;
      }

      .dashed-line-prev {
        left: 0.15em;
      }

      .btn-circle {
        font-size: 0.75em;
        border: 1px solid $light-gray;
        color: $light-gray;
        background-color: white;
        -webkit-box-shadow: 0px 0px 0px 10px rgba(255, 255, 255, 1);
        -moz-box-shadow: 0px 0px 0px 10px rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 0px 10px rgba(255, 255, 255, 1);
      }

      p {
        font-size: 0.8em;
        color: $light-gray;
        height: 29px;
      }

      &.active {
        .btn-circle {
          border: 1px solid $orange;
          color: $orange;
        }

        p {
          font-size: 1em;
          color: $orange;
        }

        .dashed-line-prev {
          border-top: 2px dashed $orange;
        }
      }

      &.completed {
        .btn-circle {
          border: 1px solid $orange;
          background-color: $orange;
          color: $white;
        }

        p {
          font-size: 0.8em;
          color: $orange;
          opacity: 0.4;
        }

        .dashed-line-prev,
        .dashed-line-next {
          border-top: 2px dashed $orange;
        }
      }
    }
  }
}

/* Structure */

.fixed-left {
  width: 33%;
  max-width: 320px;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: opacity 1.5s ease-in-out;
    -moz-transition: opacity 1.5s ease-in-out;
    -o-transition: opacity 1.5s ease-in-out;
    transition: opacity 1.5s ease-in-out;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);

    &.opaque {
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=1);
    }

    &.logo {
      width: 70%;
      height: auto;
      max-width: 300px;
      position: absolute;
      top: 2em;
      left: 2.2em;
      z-index: 999;
      opacity: 1;
    }
  }

  .slider-caption {
    position: absolute;
    width: 100%;
    bottom: 0;
    font-size: 1em;
    color: $white;
    z-index: 999;
    text-align: left;
    padding: 8em 2em 1em 2em;
    background: rgba(0, 0, 0, 0);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 49%, rgba(0, 0, 0, 0.8) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(49%, rgba(0, 0, 0, 0.8)), color-stop(100%, rgba(0, 0, 0, 0.8)));
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 49%, rgba(0, 0, 0, 0.8) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 49%, rgba(0, 0, 0, 0.8) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 49%, rgba(0, 0, 0, 0.8) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 49%, rgba(0, 0, 0, 0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=0);
    -webkit-transition: opacity 1.5s ease-in-out;
    -moz-transition: opacity 1.5s ease-in-out;
    -o-transition: opacity 1.5s ease-in-out;
    transition: opacity 1.5s ease-in-out;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);

    &.opaque {
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=1);
    }
  }

  .slider-dots {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1em;
    font-size: 3em;
    line-height: 0.2em;
    font-weight: bold;
    color: $white;
    z-index: 999;

    span {
      -webkit-transition: opacity 1.5s ease-in-out;
      -moz-transition: opacity 1.5s ease-in-out;
      -o-transition: opacity 1.5s ease-in-out;
      transition: opacity 1.5s ease-in-out;
      opacity: 0.3;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0.3)";
      filter: alpha(opacity=0.3);
      padding: 0 0.07em;
      cursor: pointer;
    }

    span.opaque {
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=1);
    }
  }
}

/* Checkout table */
.checkout-row {
  background-color: $gray-bg;
  margin-bottom: 2em;
  padding: 2em 1em;

  .checkout-col {
    padding-top: 0.5em;
    padding-bottom: 0.5em;

    &.text-center {
      text-align: left !important;
    }

    &.no-wrap {
      height: 3em;
      overflow: hidden;
    }
  }

  .subtotal-value {
    border-bottom: 1px solid $gray-minimal;
  }

  &.total {
    border-top: 1px solid $gray-minimal;
  }
}

.checkout-banner {
  background-color: #391d18;
  background-image: url('../img/background-banner-checkout.jpg');
  background-size: cover;
  background-position: 0 center;
  padding: 4em;

  a {
    color: white;
    text-decoration: underline;
  }
}

// Large devices
@media (min-width: 992px) {
  .checkout-row {
    background-color: transparent;
    margin-bottom: 0;
    padding: 0;

    .checkout-col {
      padding-top: 1em;
      padding-bottom: 1em;

      &.text-center {
        text-align: center !important;
      }
    }
  }
}