/* INPUTS */
input {
  border: 1px solid #F5F3EF;
  font-size: 0.9em;
  color: $gray;
  height: 3.6em;
  padding: 0 2em;
  outline: none;
  position: relative;
  transition: border-color 0.3s linear;
}

.input-text-wrapper {
  margin-bottom: 1em;

  &:last-of-type {
    margin-bottom: 0 !important;
  }
}

.input-number-wrapper {

  .btn-plus,
  .btn-minus {
    width: 30%;
    float: left;
    height: 2.05em;
  }

  .btn-plus {
    border-left: 0;
  }

  .btn-minus {
    border-right: 0;
  }

  input {
    width: 40%;
    float: left;
    padding: 0 1em;
    text-align: center;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}

input+label,
input+p+label {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
  max-height: 0;
  display: none;
}

input:focus {
  border: 2px solid #FF866B;

  &::placeholder {
    color: transparent;
  }
}

input:focus+label,
input:focus+p+label {
  font-size: 0.9em;
  background-color: white;
  padding: 0 0.8em;
  color: $orange;
  position: absolute;
  left: 1em;
  top: -0.8em;
  visibility: visible;
  opacity: 1;
  margin-bottom: 0.8em;
  max-height: 3em;
  display: block;
}

::-webkit-input-placeholder {
  color: #7C7977;
}

/* Edge */
:-ms-input-placeholder {
  color: #7C7977;
}

/* Internet Explorer */
::placeholder {
  color: #7C7977;
}