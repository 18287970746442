$font-sizes: (1: 1em, 11: 1.1em, 12: 1.2em, 13: 1.3em, 14: 1.4em, 15: 1.5em, 16: 1.6em, 18: 1.8em, 19: 1.9em, 2: 2em, 25: 2.5em, 3: 3em, 35: 3.5em, 4: 4em, 45: 4.5em, 5: 5em, 55: 5.5em, 6: 6em, 140: 14em);

@each $font-size-name,
$font-size in $font-sizes {
  .fs-#{$font-size-name} {
    font-size: $font-size !important;
  }
}

$font-weights: (100: 100, 200: 200, 300: 300, 400: 400, 500: 500, 600: 600, 700: 700, 800: 800, 900: 900);

@each $font-weight-name,
$font-weight in $font-weights {
  .fw-#{$font-weight-name} {
    font-weight: $font-weight !important;
  }
}

.fs-09 {
  font-size: 0.9em !important;
}

.fs-08 {
  font-size: 0.8em !important;
}

.fs-07 {
  font-size: 0.7em !important;
}

.fs-06 {
  font-size: 0.6em !important;
}

$line-heights: (1: 1em, 11: 1.1em, 12: 1.2em, 15: 1.5em, 2: 2em, 25: 2.5em, 28: 2.8em, 3: 3em, 35: 3.5em, 4: 4em, 45: 4.5em, 5: 5em, 6: 6em);

@each $line-height-name,
$line-height in $line-heights {
  .lh-#{$line-height-name} {
    line-height: $line-height !important;
  }
}

.bold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}


/* COLORES */
.white {
  color: $white !important;
}

.red {
  color: $red !important;
}

.orange {
  color: $orange !important;
}

.gray {
  color: $gray !important;
}

.gray07 {
  color: $gray07 !important;
}

.dark-gray {
  color: $dark-gray !important;
}

.price-gray {
  color: $price-gray !important;
}

.edit-gray {
  color: $edit-gray !important;
}

.capitalize { text-transform: capitalize !important; }