$white: #ffffff;
$white-gray: #fcfcfc;
$black: #000000;
$red: #ff0000;
$orange: #FF866B;
$orange-light: rgba(255, 134, 107, 0.1);
$gray: #7C7977;
$gray07: rgba(124, 121, 119, 0.7);
$dark-gray: #463B34;
$light-gray: #EFECE6;
$price-gray: rgba(64, 64, 64, 0.4);
$edit-gray: #C6C6C6;
$gray-border-select: #F5F3EF;
$gray-bg: #f9f9f9;
$gray-minimal: rgba(151, 151, 151, 0.06);