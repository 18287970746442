.orange-badge {
  background-color: $orange-light;
  color: $orange;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  display: inline-block;
  margin-left: 1em;
  display: inline-block;
  text-decoration: none;

  &:hover {
    color: $orange;
    text-decoration: none;
  }
}