/* Ant Library override */
.ant-select {
  border: 1px solid $gray-border-select;
  border-radius: 0;
  outline: none;
  box-shadow: none;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 0;
  height: auto;
}

.ant-select-selector span {
  line-height: 3.5em !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  border-right: 1px solid $gray-border-select;
  right: 3.5em;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: transparent;
  box-shadow: none;
}

.ant-select-selection-search-input {
  font-size: 1.03em;
}

.ant-select-arrow {
  font-size: 1.2em;
  color: $orange;
  top: 1.4em;
  right: 1.2em;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 40px;
}

.ant-select-selection-placeholder {
  color: $gray;
  opacity: 1;
}

.ant-select-item {
  line-height: 1.6em;
}

span.anticon {
  display: inline-block;
  vertical-align: middle;
}

.ant-popover {
  max-width: 400px;

  .ant-popover-inner-content {
    padding: 2.5em;
  }

  p {
    color: $gray;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.ant-checkbox-inner {
  border: 1px solid $gray-border-select;
  background: #FBFAF9;
  width: 24px;
  height: 24px;

  &::after {
    width: 7px;
    height: 12px;
    left: 30%;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $orange;
  border-color: $orange;
}

.ant-checkbox-checked::after {
  border: 1px solid $orange;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: $orange;
}

.ant-radio{ top: 3px; }

.ant-radio-wrapper:hover .ant-radio, 
.ant-radio:hover .ant-radio-inner, 
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $orange;
}

.ant-radio-inner {
  width: 24px;
  height: 24px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $orange;
  background-color: $orange;
}

.ant-radio-inner::after{
  background-color: $white;
  top: 7px;
  left: 7px;
}

.ant-btn-primary {
  color: #fff;
  background-color: $orange;
  border-color: $orange;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);

  &:hover {
    opacity: 0.8;
    color: #fff !important;
    background-color: $orange !important;
    border-color: $orange !important;
  }
}

.ant-btn {
  &:hover {
    opacity: 0.85;
    color: $orange;
    background-color: $white;
    border-color: $orange;
  }
}

// Ant table override
.ant-table {
  padding-bottom: 44px;
  box-shadow: 0 4px 12px 0 #F5F3EF;
}

.ant-table-thead {
  &>tr>th {
    @extend .fs-11;
    background-color: #fff;
    padding: 30px;
    color: rgba(#404040, .19);
    z-index: -2;

    &:first-child {
      padding-left: 56px;
    }

    &:last-child {
      padding: 0;
      margin: 0;
    }
  }
}

.ant-table-tbody {
  &>tr>td {
    padding: 18px;
    border-bottom: none;

    &:first-child {
      padding-left: 56px;
    }

    &:last-child {
      padding: 0;
      margin: 0;
    }
  }
}

.ant-table-tbody>tr.ant-table-row:hover>td {
  background-color: #fff;
}


.ant-table-tbody>tr.ant-table-row:hover:after {
  content: "";
  display: inline-block;
  position: absolute;
  /* top: 50%; */
  left: 50%;
  width: calc(100% + 32px);
  height: 72px;
  background-color: #fff;
  transform: translate(-50%, -4px);
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.05);
  z-index: -1;
  transition: all .1s ease-in-out;
}