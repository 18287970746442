// Menu item 
.menu-container {
  @media screen and (max-width: 1024px) {

    &:after {
      content: "";
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all .1s ease-in;
      // transition-delay: .3s;
    }

    &.active {
      &:after {
        content: "";
        opacity: 1;
        position: fixed;
        z-index: 999;
        top: 0;
        left: 248px;
        right: 0;
        bottom: 0;
        background-color: rgba($dark-gray, .1);
      }
    }

  }

  .menu-content {
    background-color: $white;
    padding: 48px 48px 32px;
    display: flex;
    flex-direction: column;
    width: 320px;
    min-height: 100vh;

    @media screen and (max-width: 1024px) {
      position: fixed;
      top: 0;
      left: -248px;
      max-width: 248px;
      padding: 24px 24px 20px;
      transition: all .1s ease;
      z-index: 1000;

      &.active {
        left: 0;
      }
    }

    img.logo {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 182px;

      @media screen and (max-width: 1024px) {
        max-width: 142px;
      }
    }


    .logo-text {
      color: $dark-gray;
      font-family: Lato;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: (20/14);
      margin-top: 8px;

      @media screen and (max-width: 1024px) {
        font-size: 10.5px;
      }
    }

    .menu-greetings {
      max-width: 220px;
      margin-bottom: 40px;

      p {
        color: $gray;
        font-size: 16px;
        line-height: (25/16);

        &.name {
          color: $dark-gray;
          font-weight: bold;
        }
      }
    }

    .menu-items {
      ul {
        padding: 0;

        li {
          list-style: none;
          padding: 13px 0;

          a {
            color: $edit-gray;
            text-decoration: none;
            font-size: 16px;
          }

          cursor: pointer;

          &.active {
            border-right: 3px solid $orange;
            @media screen and (max-width: 1024px) {
              border-right: none;
            }

            a {
              color: $dark-gray;
            }
          }

          p {
            cursor: pointer;

            a {
              color: $edit-gray;
              text-decoration: none;
            }

          }

          svg {
            margin-right: 16px;
          }
        }
      }

      p {
        cursor: pointer;

        a {
          color: $edit-gray;
          text-decoration: none;

          &:hover {
            color: $orange;
          }
        }

      }
    }

    .menu-footer {
      margin-top: auto;

      p {
        color: $gray;
        font-size: 16px;
        line-height: (25/16);
        margin-bottom: 0;

        &.name {
          color: $dark-gray;
          font-weight: bold;
        }
      }
    }
  }

  .menu-mobile {
    display: none;

    @media screen and (max-width: 1024px) {
      height: 72px;
      padding: 0 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .menu-icon {
        position: absolute;
        left: 24px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }


}