// Helpers
.split-container {
  display: flex;
  min-height: 100vh;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  .container {
    padding: 128px 60px;

    @media screen and (max-width: 1024px) {
      padding: 0 24px 60px;
    }
  }
}

.border-bottom-gray {
  border-bottom: 1px solid $edit-gray;
}

.border-radius-100 {
  border-radius: 100px;
}

.vh100 {
  min-height: 100vh;
}

.watermark-bottom-right {
  background-image: url(../img/bg_logo.svg);
  background-position: right bottom;
  background-repeat: no-repeat;
}

.overflow-auto {
  overflow: auto;
}