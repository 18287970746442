* {
  font-family: "Lato", sans-serif;
  box-sizing: border-box;
  outline: none !important;
}

body {
  font-size: 12px;
  background-color: $white-gray;
  color: $gray;
}

h1 {
  font-size: 1.9rem;
  font-weight: bold;
  letter-spacing: 1px;
}

h2 {
  font-size: 1.3rem;
  font-weight: bold;
}

a {
  color: $gray;
  text-decoration: underline;

  &:hover {
    color: $orange;
  }
}


// Small devices
@media (min-width: 576px) {
  body {
    font-size: 12px;
  }
}

// Medium devices
@media (min-width: 768px) {
  body {
    font-size: 12px;
  }
}

// Large devices
@media (min-width: 992px) {
  body {
    font-size: 14px;
  }
}

// Extra large devices
@media (min-width: 1200px) {
  body {
    font-size: 16px;
  }
}